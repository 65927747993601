.message-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.message-bubble {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 5px; /* Add margin for spacing between bubbles */
  max-width: 240px; /* Set a maximum width for the bubble */
  word-wrap: break-word; /* Allow long words to be broken and wrap onto the next line */
}

.btn-circle {
  border-radius: 50%;
  width: 30px; /* Set the width of the button */
  height: 30px; /* Set the height of the button */
  font-size: 14px; /* Adjust font size */
  font-weight: bold; /* Make the font bold */
  margin-left: 5px; /* Add margin to separate the button from the text */
}
